import getChildren from './getChildren.utils';
const getParentNode = (prefix, icon, children, starterPath) => ({
    key: prefix,
    name: prefix,
    icon: `icon ic-${icon} !block h-[20px] dark:text-general-100 text-general-800 dark:group-hover:text-general-800`,
    children: children
        .map((child) => getChildren(prefix, child, starterPath))
        .filter((child) => child.visible),
    visible: !!children
        .map((child) => getChildren(prefix, child, starterPath))
        .filter((child) => child.visible).length,
});
export default getParentNode;
