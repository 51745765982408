import { createRouter, createWebHistory } from 'vue-router';
const routes = [
// {
//   path: '/',
//   name: 'home',
//   component: () => import('@/views/HomeView.vue'),
// },
// {
//   path: '/:pathMatch(.*)*',
//   name: 'not-found',
//   meta: {
//     hasAccess: true,
//   },
//   component: () => import('@/views/404.vue'),
// },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
