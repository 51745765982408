import getParentNode from '@/utils/getParentNode.utils';
const children = [
    'Pipeline',
    'License Trial',
    'SPRF',
    'QRF',
    'Quotation',
    'Form Penagihan',
    'End User Data Form',
];
export default getParentNode('Sales', 'line-chart', children);
