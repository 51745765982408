import getParentNode from '@/utils/getParentNode.utils';
const children = [
    'Approval',
    'Email',
    'User',
    'Role',
    'Masa Expired',
    'Custom Field',
    'Term & Condition',
];
export default getParentNode('Settings', 'settings', children, 'pengaturan');
