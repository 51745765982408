import getParentNode from '@/utils/getParentNode.utils';
const children = [
    'Performa Invoice',
    'Bukti Bayar',
    'Load PO',
    'Faktur Pajak',
    'Invoice',
    'Delivery Order',
    'Nomor Surat',
];
export default getParentNode('Admin', 'calculator', children);
