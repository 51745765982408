import { WangsVue, Tooltip, ToastService } from 'wangsvue';
import Preset from 'wangsvue-presets/wangsvue';
import '@/assets/css/main.css';
import 'wangsvue/style.css';
import 'wangsvue-presets/wangsvue/style.css';
import router from '@/router';
const registerComponents = (app) => {
    app.use(WangsVue, {
        unstyled: true,
        pt: Preset,
    });
    app.provide('preset', Preset);
    app.use(ToastService);
    app.use(router);
    app.directive('Tooltip', Tooltip);
};
export default registerComponents;
