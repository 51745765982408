import Dashboard from './dashboard';
import Karyawan from './karyawan';
import Settings from './settings';
import Approval from './approval';
import Crm from './crm';
import Admin from './admin';
import Sales from './sales';
import AfterSales from './aftersales';
import PriceInfo from './priceinfo';
const nodes = [
    Dashboard,
    Karyawan,
    Crm,
    Sales,
    Admin,
    Approval,
    AfterSales,
    PriceInfo,
    Settings,
];
export default nodes;
