import getParentNode from '@/utils/getParentNode.utils';
const children = [
    'Produk',
    'License Trial',
    'SPRF',
    'QRF',
    'Quotation',
    'Form Penagihan',
    'Cashback',
    'ELSA',
];
export default getParentNode('Approval', 'chat-check', children);
