export default {
    root: {
        class: [
            'relative',
            // Space
            'pb-3',
            // Shape
            'rounded-md',
            // Color
        ],
    },
    wrapper: {
        class: ['overflow-auto'],
    },
    container: {
        class: [
            // Display
            'grid',
            // Spacing
            'm-0 p-0 gap-1',
            // Misc
            'list-none overflow-auto',
        ],
    },
    node: {
        class: [
            'rounded-md grid gap-0.5',
            'focus:outline-none focus:outline-offset-0',
        ],
    },
    content: ({ context, props }) => {
        const selectedKeys = Object.keys(props.selectionKeys);
        const children = props.node?.children ?? [];
        let activeParent = false;
        let activeGrandParent = false;
        // Kondisi untuk menentukan parent / grand parent yang memiliki child yang aktif
        if (selectedKeys.length) {
            activeParent = children
                .map((child) => child.key)
                .includes(selectedKeys[0]);
            const splittedKey = selectedKeys[0].split('-');
            if (splittedKey.length === 3) {
                activeGrandParent = props.node.key === splittedKey[0];
            }
        }
        return {
            class: [
                // Flex and Alignment
                'flex items-center',
                'relative',
                // Shape
                'rounded-md',
                // Spacing
                'px-[8px]',
                { 'py-[5px]': props.node.icon, 'py-[6px]': !props.node.icon },
                'gap-[6px]',
                // Colors
                {
                    'bg-primary-500 text-white dark:bg-primary-300 [&_span.icon]:dark:text-general-800': context.selected,
                    'bg-general-50 active-parent [&_span]:dark:text-general-800 [&_span.icon]:dark:text-general-800': activeParent || activeGrandParent,
                },
                // States
                {
                    'hover:bg-general-50 inactive group': !context.selected,
                },
                // Transition
                'transition-shadow duration-200',
                {
                    'select-none': props.selectionMode == 'single' ||
                        props.selectionMode == 'multiple',
                },
                // Miscelanous
                'cursor-pointer',
            ],
        };
    },
    toggler: ({ context }) => ({
        class: [
            // Flex and Alignment
            'inline-flex items-center justify-center',
            'absolute right-[5px]',
            'shrink-0',
            // Shape
            'border-0 rounded-full',
            // Size and Spacing
            'w-4 h-4',
            // Spacing
            // 'mr-0.5',
            // Colors
            'text-general-800 dark:text-general-800',
            'bg-transparent',
            {
                invisible: context.leaf,
            },
            // States
            // 'hover:text-surface-700 dark:hover:text-white/80',
            // 'hover:bg-surface-100 dark:hover:bg-surface-800/80',
            // 'focus:ring-primary-500 dark:focus:ring-primary-400',
            // Transition
            'transition duration-200',
            // Misc
            'cursor-pointer select-none',
        ],
    }),
    togglericon: {
        class: [
            // Size
            'w-4 h-4',
            // Color
            'dark:text-white text-black',
        ],
    },
    nodeCheckbox: {
        root: {
            class: [
                'relative',
                // Alignment
                'inline-flex',
                'align-bottom',
                // Size
                'w-4',
                'h-4',
                // Spacing
                'mr-2',
                // Misc
                'cursor-default',
                'select-none',
            ],
        },
        box: ({ props, context }) => ({
            class: [
                // Alignment
                'flex',
                'items-center',
                'justify-center',
                // Size
                'w-4',
                'h-4',
                // Shape
                'rounded',
                'border',
                // Colors
                'text-surface-600',
                {
                    'border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-900': !context.checked,
                    'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400': context.checked,
                },
                {
                    'ring-2 ring-primary-500 dark:ring-primary-400': !props.disabled && context.focused,
                    'cursor-default opacity-60': props.disabled,
                },
                // States
                {
                    'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400': !props.disabled,
                    'cursor-default opacity-60': props.disabled,
                },
                // Transitions
                'transition-colors',
                'duration-200',
            ],
        }),
        input: {
            class: [
                'peer',
                // Size
                'w-full ',
                'h-full',
                // Position
                'absolute',
                'top-0 left-0',
                'z-10',
                // Spacing
                'p-0',
                'm-0',
                // Shape
                'rounded',
                'border',
                // Shape
                'opacity-0',
                'rounded-md',
                'outline-none',
                'border-2 border-surface-300 dark:border-surface-700',
                // Misc
                'appareance-none',
            ],
        },
        icon: {
            class: [
                // Font
                'text-normal',
                // Size
                'w-3',
                'h-3',
                // Colors
                'text-white dark:text-surface-900',
                // Transitions
                'transition-all',
                'duration-200',
            ],
        },
    },
    nodeicon: ({ context }) => ({
        class: [
            '!block h-[20px] dark:group-hover:text-general-800',
            { 'dark:text-general-100 text-general-800': !context.selected },
            { 'dark:text-white !text-white': context.selected },
        ],
    }),
    subgroup: {
        class: [
            'm-0 list-none p-0 !pl-2 gap-0.5 grid duration-300 transition-all ease-in',
        ],
    },
    filtercontainer: {
        class: [
            'relative block',
            // Space
            'mb-2',
            // Size
            'w-full',
        ],
    },
    input: {
        class: [
            'relative',
            // Font
            'font-sans leading-6',
            'sm:text-sm',
            // Spacing
            'm-0',
            'py-1.5 px-3 pr-10',
            // Size
            'w-full',
            // Shape
            'rounded-md',
            // Colors
            'text-surface-900 dark:text-surface-0',
            'placeholder:text-surface-400 dark:placeholder:text-surface-500',
            'bg-surface-0 dark:bg-surface-900',
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700 ring-offset-0',
            // States
            'hover:border-primary-500 dark:hover:border-primary-400',
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400',
            // Transition & Misc
            'appearance-none',
            'transition-colors duration-200',
        ],
    },
    loadingicon: {
        class: [
            'text-surface-500 dark:text-surface-0/70',
            'absolute top-[50%] right-[50%] -mt-2 -mr-2 animate-spin',
        ],
    },
    searchicon: {
        class: [
            // Position
            'absolute top-1/2 -mt-2 right-3',
            // Color
            'text-surface-600 dark:hover:text-white/70',
        ],
    },
    label: ({ context }) => ({
        class: [
            'inline-flex justify-between items-center',
            'w-full',
            'text-xs font-medium',
            {
                'text-general-800 dark:text-general-100 dark:group-hover:text-general-800': !context.selected,
            },
            { 'text-white dark:text-general-800': context.selected },
        ],
    }),
};
